import styled from "@emotion/styled"
import React from "react"
import { mediaQueries } from "./breakpoints"
import { css } from "@emotion/react"
import { Section } from "./Section"
import { Container } from "./Container"
import iconFacebook from "../images/facebook.svg"
import iconTwitter from "../images/twitter.svg"
import iconLinkedin from "../images/linkedin.svg"

const graduates = [
  {
    name: "Martina Bacíková",
    description: "Zakladatelka a ředitelka Inistutu ekonomického vzdělávání (INEV), ředitelka Ekonomické olympiády",
    facebook: "https://www.facebook.com/martina.bacikova",
    linkedin: "https://www.linkedin.com/in/martina-bacikova/",
    twitter: "https://twitter.com/BacikovaMartina",
  },
  {
    name: "Jan Škapa",
    description: "Students for Liberty",
    facebook: "https://www.facebook.com/jan.skapa",
    linkedin: "https://www.linkedin.com/in/janskapa/",
    twitter: null,
  },
  {
    name: "Michal Šindlář",
    description: "Students for Liberty",
    facebook: "https://www.facebook.com/michal.sindlar",
    linkedin: "https://www.linkedin.com/in/michal-%C5%A1indl%C3%A1%C5%99-a0577886/",
    twitter: null,
  },
  {
    name: "Pavel Peterka",
    description: "Hlavní ekonom skupiny Roklen",
    facebook: "https://www.facebook.com/pavel.peterka.5",
    linkedin: "https://www.linkedin.com/in/pavel-peterka-ba513a163/",
    twitter: "https://twitter.com/pavel_peterka",
  },
  {
    name: "Michael Fanta",
    description: "Seniorní analytik Centra ekonomických a tržních analýz (CETA)",
    facebook: "https://www.facebook.com/miky.fanta.7",
    linkedin: "https://www.linkedin.com/in/michael-fanta-8a264b106/",
    twitter: null,
  },
  {
    name: "Jiří Schwarz ml.",
    description: "Přednášející ekonomie na Anglo-American University (AAU) a bývalý poradce členů bankovní rady České národní banky (ČNB)",
    facebook: "https://www.facebook.com/schwarzjr",
    linkedin: "https://www.linkedin.com/in/jiri-schwarz-88473416/",
    twitter: null,
  },
  {
    name: "Tomáš Jirsa",
    description: "CEO at C&B Reputation Management a bývalý poradce primátora hl. m. Prahy",
    facebook: "https://www.facebook.com/tomas.jirsa",
    linkedin: "https://www.linkedin.com/in/tomasjirsa/",
    twitter: "https://twitter.com/tomasjirsa",
  },
  {
    name: "Josef Tětek",
    description: "Stackuj.cz, podcaster, ambasador hardwarové peněženky Trezor",
    facebook: "https://www.facebook.com/satsjoseph",
    linkedin: "https://www.linkedin.com/in/joseftetek/",
    twitter: "https://twitter.com/SatsJoseph",
  },
  {
    name: "Jakub Skala",
    description: "COO a ředitel publikací Liberálního institutu",
    facebook: "https://www.facebook.com/jakub.skala.10",
    linkedin: "https://www.linkedin.com/in/jakub-skala/",
    twitter: "https://twitter.com/JJSkala",
  },
  {
    name: "Martin Froněk",
    description: "Local Partner u White & Case LLP",
    facebook: null,
    linkedin: "https://www.linkedin.com/in/martin-fron%C4%9Bk-7695a31b/",
    twitter: null,
  },
  {
    name: "Michal Čarný",
    description: "General Manager for Czech Republic and Slovakia at Mastercard",
    facebook: "https://www.facebook.com/michal.carny.5",
    linkedin: "https://www.linkedin.com/in/carny/",
    twitter: null,
  },
  {
    name: "Jan Hlavsa",
    description: "Founder at Fondee",
    facebook: "https://www.facebook.com/honzahlavsa",
    linkedin: "https://www.linkedin.com/in/jan-hlavsa-240034b/",
    twitter: null,
  },
  {
    name: "Michal Matyáš",
    description: "Finance Lead Janssen Czech Republic, Slovakia, Baltics",
    facebook: null,
    linkedin: "https://www.linkedin.com/in/michalmatyas1/",
    twitter: null,
  },
  {
    name: "František Jíša",
    description: "Financial Director & Board Member at J&T REAL ESTATE CZ",
    facebook: "https://www.facebook.com/frantisekj",
    linkedin: "https://www.linkedin.com/in/frantisekjisa/",
    twitter: null,
  },
  {
    name: "Pavel Vaněk",
    description: "CFO Europe at PPF Real Estate",
    facebook: null,
    linkedin: "https://www.linkedin.com/in/pavel-vanek-00905a2/",
    twitter: null,
  },
  {
    name: "Markéta Hlaváčová",
    description: "Global Commercial Director at Bolt",
    facebook: null,
    linkedin: "https://www.linkedin.com/in/marketa-hlavacova-61611a10/",
    twitter: null,
  },
  {
    name: "Petr Havlík",
    description: "Analytical Consultant at Google",
    facebook: "https://www.facebook.com/pedrito.cz",
    linkedin: "https://www.linkedin.com/in/havlik/",
    twitter: null,
  }
]

const GraduatesGrid = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: -1rem;
  margin-right: -1rem;
  ${mediaQueries.medium(
    css`
      margin: 0;
    `
  )}
  ${mediaQueries.large(
    css`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `
  )}
`
const Graduate = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  &::before {
    content: "";
    display: table;
    padding-top: 100%;
  }
  ${mediaQueries.largeDown(
    css`
      &:nth-child(4n+1),
      &:nth-child(4n+4),
      &:nth-child(4n){
        background-color: #312E2E;
      }
    `
  )}
  ${mediaQueries.large(
    css`
      &:nth-child(8n+1),
      &:nth-child(8n+3),
      &:nth-child(8n+6),
      &:nth-child(8n){
        background-color: #312E2E;
      }
    `
  )}
`

const GraduateContent = styled("div")`
  width: 100%;
  padding: 2rem;
  ${mediaQueries.smallDown(
    css`
      padding: 1.5rem 1rem;
    `
  )}
`

const GraduateName = styled("h3")`
  color: var(--yellow);
  ${mediaQueries.mediumDown(
    css`
      font-size: 1.3rem;
    `
  )}
  ${mediaQueries.smallDown(
    css`
      font-size: 1.2rem;
    `
  )}
`

const GraduateYear = styled("h4")`
  font-weight: 400;
  line-height: 1.5;
  ${mediaQueries.mediumDown(
    css`
      font-size: 1rem;
    `
  )}
`

const GraduateDescription = styled("h4")`
  font-weight: 400;
  line-height: 1.5;
  ${mediaQueries.mediumDown(
    css`
      font-size: 1rem;
    `
  )}
`

const GraduateIcons = styled("div")`
  margin-top: 1rem;
  &:empty {
    display: none;
  }
`

const GraduateIcon = styled("div")`
  display: inline-block;
  width: 2rem;
  text-align: center;

  a, button {
    width: 100%;
    display: inline-block;
    transform: scale(1);
    transition: all .1s ease-in-out;
    @media (hover: hover) {
      &:hover {
        transform: scale(1.15);
        filter: drop-shadow(0 0 2px var(--yellow));
      }
    }
  }
`

export default function Graduates() {
  return (
    <Section id="absolventi">
      <Container>
        <h2>VÝZNAMNÍ ABSOLVENTI</h2>
        <GraduatesGrid>
          {graduates.map((graduate, index) => (
            <Graduate key={index}>
              <GraduateContent>
                <GraduateName>{graduate.name}</GraduateName>
                <GraduateDescription>{graduate.description}</GraduateDescription>
                <GraduateIcons>
                  {graduate.facebook && (
                    <GraduateIcon>
                      <a target="blank" rel="noopener noreferrer" href={graduate.facebook}><img src={iconFacebook} /></a>
                    </GraduateIcon>
                  )}
                  {graduate.twitter && (
                    <GraduateIcon>
                      <a target="blank" rel="noopener noreferrer" href={graduate.twitter}><img src={iconTwitter} /></a>
                    </GraduateIcon>
                  )}
                  {graduate.linkedin && (
                    <GraduateIcon>
                      <a target="blank" rel="noopener noreferrer" href={graduate.linkedin}><img src={iconLinkedin} /></a>
                    </GraduateIcon>
                  )}
                </GraduateIcons>
              </GraduateContent>
            </Graduate>
          ))}
        </GraduatesGrid>
      </Container>
    </Section>
    )
}

